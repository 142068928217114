export const DISCOUNT: Record<
  string,
  {
    discount: number;
    footnote?: string;
    apartmentSlugs?: Record<
      string,
      {
        discount: number;
        footnote?: string;
      }
    >;
    apartmentTypes?: Record<
      string,
      {
        discount: number;
        footnote?: string;
      }
    >;
  }
> = {
  hamburg: {
    discount: 0,
    footnote: "discount-footnote",
    apartmentSlugs: undefined,
    apartmentTypes: {
      coliving: {
        discount: 15,
        footnote: "discount-footnote",
      },
    },
  },
  paris: {
    discount: 0,
    apartmentSlugs: {
      victor: {
        discount: 20,
        footnote: "discount-footnote",
      },
    },
    apartmentTypes: undefined,
    footnote: undefined,
  },
  berlin: {
    discount: 0,
    footnote: "discount-footnote",
    apartmentSlugs: {
      "rudolf-15": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-20": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-27": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-33": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-34": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-30": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "flora-43": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "invalidenstr-34": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-06": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-05": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-16": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-25": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
    },
    apartmentTypes: undefined,
  },
  oslo: {
    discount: 0,
    footnote: "discount-footnote",
    apartmentTypes: undefined,
    apartmentSlugs: {
      "hedda-1": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-2": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-3": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-4": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-5": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-6": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-7": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-8": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-9": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-10": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-11": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
      "hedda-12": {
        discount: 15,
        footnote: "discount-footnote-hedda",
      },
    },
  },
  copenhagen: {
    discount: 0,
    footnote: "discount-footnote-copenhagen",
    apartmentTypes: undefined,
  },
};

export const DEPOSIT: Record<string, { shared: number; private: number }> = {
  aarhus: {
    private: 10000,
    shared: 10000,
  },
  copenhagen: {
    private: 10000,
    shared: 10000,
  },
  oslo: {
    private: 0,
    shared: 0,
  },
  berlin: {
    private: 1000,
    shared: 1000,
  },
  halle: {
    private: 1000,
    shared: 1000,
  },
  hamburg: {
    private: 1800,
    shared: 1000,
  },
  munich: {
    private: 1000,
    shared: 1000,
  },
  paris: {
    private: 1000,
    shared: 1000,
  },
  london: {
    private: 1300,
    shared: 1300,
  },
};
